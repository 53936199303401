<template>
  <div v-if="Object.keys(infos).length > 0">
    <!--  -->
    <b-field grouped group-multiline>
      <b-select-with-validation
        vid="role"
        :name="$t('policies.rao.role.label')"
        v-model="infos.role.value"
        :placeholder="$t('policies.rao.role.placeholder')"
        :select-attrs="{
          disabled: infos.role.disabled,
        }"
        :rules="{ required: infos.role.required }"
      >
        <template #label
          ><span class="has-text-light">{{
            `${$t("policies.rao.role.label")}${infos.role.required ? "*" : ""}`
          }}</span></template
        >
        <template #default>
          <option :value="null"></option>
          <option v-for="r in roleList" :key="r.name" :value="r.name">
            {{ $t(`policies.roles.${r.name}`) }}
          </option>
        </template>
      </b-select-with-validation>

      <template v-if="infos.role.value !== 'customer'">
        <ValidationProvider
          vid="isRAO"
          name="isRAO"
          :rules="{ required: infos.isRAO.required }"
          v-slot="{ errors }"
          slim
        >
          <!-- RAO radio button field -->
          <b-field
            class="is-flex is-flex-direction-column"
            :type="{ 'is-danger': errors[0] }"
            :message="errors.length > 0 ? errors : null"
          >
            <template #label
              ><span class="has-text-light">{{
                `${$t("policies.rao.isRAO.label")}${
                  infos.isRAO.required ? "*" : ""
                }`
              }}</span></template
            >
            <!-- RAO radio NO -->
            <b-radio
              v-model="infos.isRAO.value"
              :native-value="false"
              type="is-white"
              :disabled="infos.isRAO.disabled"
            >
              <span class="has-text-light">{{ $t("no") }}</span>
            </b-radio>
            <!-- RAO radio YES -->
            <b-radio
              v-model="infos.isRAO.value"
              :native-value="true"
              type="is-white"
              :disabled="infos.isRAO.disabled"
            >
              <span class="has-text-light">{{ $t("yes") }}</span>
            </b-radio>
          </b-field>
        </ValidationProvider>
      </template>

      <!-- Username input -->
      <b-input-with-validation
        ref="domainUsername"
        vid="domainUsername"
        :name="$t('policies.rao.domainUsername.label')"
        v-model="infos.domainUsername.value"
        :input-attrs="{
          placeholder: $t('policies.rao.domainUsername.placeholder'),
          disabled: infos.domainUsername.disabled,
          loading: checkingUsername,
        }"
        :fieldAttrs="{ expanded: false }"
        :rules="{ required: infos.domainUsername.required }"
        @change="checkUsernameExistance"
      >
        <template #label>
          <span class="has-text-light">{{
            `${$t("policies.rao.domainUsername.label")}${
              infos.domainUsername.required ? "*" : ""
            }`
          }}</span>
        </template>
      </b-input-with-validation>
    </b-field>

    <!-- Email group -->
    <b-field grouped group-multiline>
      <!-- Email input text -->
      <b-input-with-validation
        vid="email"
        :name="$t('policies.rao.email.label')"
        v-model="infos.email.value"
        :input-attrs="{
          placeholder: $t('policies.rao.email.placeholder'),
          disabled: infos.email.disabled,
        }"
        :fieldAttrs="{ expanded: false }"
        :rules="{ required: infos.email.required, email: true }"
        :isLowerCase="true"
        style="min-width: 16rem"
      >
        <template #label
          ><span class="has-text-light">{{
            `${$t("policies.rao.email.label")}${
              infos.email.required ? "*" : ""
            }`
          }}</span></template
        >
      </b-input-with-validation>

      <ValidationProvider
        vid="isEmailCertified"
        name="isEmailCertified"
        :rules="{ required: infos.isEmailCertified.required }"
        v-slot="{ errors }"
        slim
      >
        <!-- Email certified radio button -->
        <b-field
          class="is-flex is-flex-direction-column"
          :type="{ 'is-danger': errors[0] }"
          :message="errors.length > 0 ? errors : null"
        >
          <template #label
            ><span class="has-text-light">{{
              `${$t("policies.rao.isEmailCertified.label")}${
                infos.isEmailCertified.required ? "*" : ""
              }`
            }}</span></template
          >
          <b-radio
            v-model="infos.isEmailCertified.value"
            :native-value="false"
            type="is-white"
            :disabled="infos.isEmailCertified.disabled"
          >
            <span class="has-text-light">{{ $t("no") }}</span>
          </b-radio>
          <b-radio
            v-model="infos.isEmailCertified.value"
            :native-value="true"
            type="is-white"
            :disabled="infos.isEmailCertified.disabled"
          >
            <span class="has-text-light">{{ $t("yes") }}</span>
          </b-radio>
        </b-field>
      </ValidationProvider>
    </b-field>

    <div class="columns is-multiline">
      <div class="column">
        <!-- name -->
        <b-input-with-validation
          vid="name"
          :name="$t('policies.rao.name.label')"
          :value="infos.name.value"
          :input-attrs="{
            placeholder: $t('policies.rao.name.placeholder'),
            disabled: infos.name.disabled,
          }"
          :fieldAttrs="{ expanded: false }"
          :rules="{ required: infos.name.required }"
          @input="
            (val) =>
              (infos.name.value = val.replace(
                /\b\w+/g,
                (txt) =>
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
              ))
          "
        >
          <template #label>
            <span class="has-text-light">{{
              `${$t("policies.rao.name.label")}${
                infos.name.required ? "*" : ""
              }`
            }}</span>
          </template>
        </b-input-with-validation>
      </div>

      <div class="column">
        <!-- surname -->
        <b-input-with-validation
          vid="surname"
          :name="$t('policies.rao.surname.label')"
          :value="infos.surname.value"
          :input-attrs="{
            placeholder: $t('policies.rao.surname.placeholder'),
            disabled: infos.surname.disabled,
          }"
          :fieldAttrs="{ expanded: false }"
          :rules="{ required: infos.surname.required }"
          @input="
            (val) =>
              (infos.surname.value = val.replace(
                /\b\w+/g,
                (txt) =>
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
              ))
          "
        >
          <template #label>
            <span class="has-text-light">{{
              `${$t("policies.rao.surname.label")}${
                infos.surname.required ? "*" : ""
              }`
            }}</span>
          </template>
        </b-input-with-validation>
      </div>

      <div class="column">
        <!-- mobile number -->
        <b-input-with-validation
          vid="mobile"
          :name="$t('policies.rao.mobileNumber.label')"
          v-model="infos.mobileNumber.value"
          :input-attrs="{
            placeholder: $t('policies.rao.mobileNumber.placeholder'),
            disabled: infos.mobileNumber.disabled,
          }"
          :fieldAttrs="{ expanded: false }"
          :rules="{
            required: infos.mobileNumber.required,
            regex: '[+]{1}[0-9]{4,}',
          }"
        >
          <template #label>
            <span class="has-text-light">{{
              `${$t("policies.rao.mobileNumber.label")}${
                infos.mobileNumber.required ? "*" : ""
              }`
            }}</span>
          </template>
        </b-input-with-validation>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column">
        <!-- fiscal code -->
        <b-input-with-validation
          vid="fiscalCode"
          :name="$t('policies.rao.fiscalCode.label')"
          :value="infos.fiscalCode.value"
          :input-attrs="{
            placeholder: $t('policies.rao.fiscalCode.placeholder'),
            disabled: infos.fiscalCode.disabled,
          }"
          :fieldAttrs="{ expanded: false }"
          :rules="{ required: infos.fiscalCode.required }"
          @input="
            (val) => (infos.fiscalCode.value = val ? val.toUpperCase() : val)
          "
        >
          <template #label>
            <span class="has-text-light">{{
              `${$t("policies.rao.fiscalCode.label")}${
                infos.fiscalCode.required ? "*" : ""
              }`
            }}</span>
          </template>
        </b-input-with-validation>
      </div>

      <div class="column">
        <ValidationProvider
          vid="birthCountry"
          name="birthCountry"
          :rules="{ required: infos.birthCountry.required }"
          v-slot="{ errors }"
          slim
        >
          <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
            <template #label>
              <span class="has-text-light">{{
                `${$t("policies.rao.fiscalCountry.label")}${
                  infos.fiscalCountry.required ? "*" : ""
                }`
              }}</span>
            </template>

            <b-autocomplete
              :value="infos.fiscalCountry.value"
              ref="autocomplete"
              :data="filteredFiscalCountries"
              field="code"
              :placeholder="$t('policies.rao.fiscalCountry.placeholder')"
              :disabled="infos.fiscalCountry.disabled"
              @input="
                (val) =>
                  (infos.fiscalCountry.value = val ? val.toUpperCase() : val)
              "
              @select="
                (option) =>
                  (infos.fiscalCountry.value = option ? option.code : null)
              "
            >
              <!-- <template #empty>No results for {{ name }}</template> -->
            </b-autocomplete>
          </b-field>
        </ValidationProvider>
      </div>
      <div class="column">
        <!-- birthDate -->
        <!-- TODO: date formatting -->
        <b-input-with-validation
          vid="birthDate"
          :name="$t('policies.rao.birthDate.label')"
          v-model="infos.birthDate.value"
          :input-attrs="{
            placeholder: $t('policies.rao.birthDate.placeholder'),
            disabled: infos.birthDate.disabled,
          }"
          :fieldAttrs="{ expanded: false }"
          :rules="{ required: infos.birthDate.required, regex: dateRegex }"
        >
          <template #label>
            <span class="has-text-light">{{
              `${$t("policies.rao.birthDate.label")}${
                infos.birthDate.required ? "*" : ""
              }`
            }}</span>
          </template>
        </b-input-with-validation>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column">
        <!-- birthPlace -->
        <b-input-with-validation
          vid="birthPlace"
          :name="$t('policies.rao.birthPlace.label')"
          v-model="infos.birthPlace.value"
          :input-attrs="{
            placeholder: $t('policies.rao.birthPlace.placeholder'),
            disabled: infos.birthPlace.disabled,
          }"
          :fieldAttrs="{ expanded: false }"
          :rules="{ required: infos.birthPlace.required }"
        >
          <template #label>
            <span class="has-text-light">{{
              `${$t("policies.rao.birthPlace.label")}${
                infos.birthPlace.required ? "*" : ""
              }`
            }}</span>
          </template>
        </b-input-with-validation>
      </div>

      <div class="column">
        <!-- birthProvince -->
        <!-- <b-input-with-validation
          vid="birthProvince"
          :name="$t('policies.rao.birthProvince.label')"
          v-model="infos.birthProvince.value"
          :input-attrs="{
            placeholder: $t('policies.rao.birthProvince.placeholder'),
            disabled: infos.birthProvince.disabled,
          }"
          :fieldAttrs="{ expanded: false }"
          :rules="{ required: infos.birthProvince.required }"
        >
          <template #label>
            <span class="has-text-light">{{
              `${$t("policies.rao.birthProvince.label")}${
                infos.birthProvince.required ? "*" : ""
              }`
            }}</span>
          </template>
        </b-input-with-validation> -->

        <ValidationProvider
          vid="birthProvince"
          name="birthProvince"
          :rules="{ required: infos.birthProvince.required }"
          v-slot="{ errors }"
          slim
        >
          <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
            <template #label>
              <span class="has-text-light">{{
                `${$t("policies.rao.birthProvince.label")}${
                  infos.birthProvince.required ? "*" : ""
                }`
              }}</span>
            </template>

            <b-autocomplete
              :value="infos.birthProvince.value"
              ref="autocomplete"
              :data="filteredProvinces"
              field="code"
              :placeholder="$t('policies.rao.birthProvince.placeholder')"
              :disabled="infos.birthProvince.disabled"
              @input="
                (val) =>
                  (infos.birthProvince.value = val ? val.toUpperCase() : val)
              "
              @select="
                (option) =>
                  (infos.birthProvince.value = option ? option.code : null)
              "
            >
              <!-- <template #empty>No results for {{ name }}</template> -->
            </b-autocomplete>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column">
        <ValidationProvider
          vid="birthCountry"
          name="birthCountry"
          :rules="{ required: infos.birthCountry.required }"
          v-slot="{ errors }"
          slim
        >
          <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
            <template #label>
              <span class="has-text-light">{{
                `${$t("policies.rao.birthCountry.label")}${
                  infos.birthCountry.required ? "*" : ""
                }`
              }}</span>
            </template>

            <b-autocomplete
              :value="infos.birthCountry.value"
              ref="autocomplete"
              :data="filteredBirthCountries"
              field="code"
              :placeholder="$t('policies.rao.birthCountry.placeholder')"
              :disabled="infos.birthCountry.disabled"
              @input="
                (val) =>
                  (infos.birthCountry.value = val ? val.toUpperCase() : val)
              "
              @select="
                (option) =>
                  (infos.birthCountry.value = option ? option.code : null)
              "
            >
              <!-- <template #empty>No results for {{ name }}</template> -->
            </b-autocomplete>
          </b-field>
        </ValidationProvider>
      </div>
    </div>

    <!-- document -->
    <!-- <div class="columns">
              <div class="column"> -->
    <b-field grouped group-multiline class="block">
      <b-select-with-validation
        vid="documentType"
        :name="$t('policies.rao.documentType.label')"
        v-model="infos.documentType.value"
        :placeholder="$t('policies.rao.documentType.placeholder')"
        :select-attrs="{
          disabled: infos.documentType.disabled,
        }"
        :rules="{ required: infos.documentType.required }"
      >
        <template #label>
          <span class="has-text-light">{{
            `${$t("policies.rao.documentType.label")}${
              infos.documentType.required ? "*" : ""
            }`
          }}</span>
        </template>

        <template #default>
          <option value="NIC">
            {{ $t("policies.rao.documentType.NIC") }}
          </option>
          <option value="DL">
            {{ $t("policies.rao.documentType.DL") }}
          </option>
          <option value="P">
            {{ $t("policies.rao.documentType.P") }}
          </option>
        </template>
      </b-select-with-validation>

      <b-input-with-validation
        vid="documentId"
        :name="$t('policies.rao.documentId.label')"
        v-model="infos.documentId.value"
        :input-attrs="{
          placeholder: $t('policies.rao.documentId.placeholder'),
          disabled: infos.documentId.disabled,
        }"
        :fieldAttrs="{ expanded: false }"
        :rules="{ required: infos.documentId.required }"
      >
        <template #label>
          <span class="has-text-light">{{
            `${$t("policies.rao.documentId.label")}${
              infos.documentId.required ? "*" : ""
            }`
          }}</span>
        </template>
      </b-input-with-validation>
    </b-field>
    <!-- </div>
              <div class="column">
                
              </div>
            </div> -->

    <div class="block">
      <p
        v-if="!organizationSwitch && !orgDataEnabled"
        class="has-text-light has-text-weight-bold"
        style="margin-top: 2rem"
      >
        {{ $t("policies.rao.orgData.not_present") }}
      </p>

      <b-field v-if="organizationSwitch" class="block" style="margin-top: 2rem">
        <b-switch v-model="orgDataEnabled" left-label size="">
          <span class="has-text-light has-text-weight-bold">{{
            modifyLater
              ? `${$t("policies.rao.orgData.enabled")} (${$t(
                  "policies.rao.orgData.not_modify_after"
                )})`
              : $t("policies.rao.orgData.enabled")
          }}</span>
        </b-switch>
      </b-field>

      <!-- organization -->
      <div class="columns" v-if="orgDataEnabled">
        <div class="column">
          <b-input-with-validation
            vid="organizationName"
            :name="$t('policies.rao.organizationName.label')"
            v-model="infos.organizationName.value"
            :input-attrs="{
              placeholder: $t('policies.rao.organizationName.placeholder'),
              disabled: infos.organizationName.disabled,
            }"
            :fieldAttrs="{ expanded: false }"
            :rules="{ required: infos.organizationName.required }"
          >
            <template #label>
              <span class="has-text-light">{{
                `${$t("policies.rao.organizationName.label")}${
                  infos.organizationName.required ? "*" : ""
                }`
              }}</span>
            </template>
          </b-input-with-validation>
        </div>

        <div class="column">
          <ValidationProvider
            vid="organizationCountry"
            name="organizationCountry"
            :rules="{ required: infos.organizationCountry.required }"
            v-slot="{ errors }"
            slim
          >
            <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
              <template #label>
                <span class="has-text-light">{{
                  `${$t("policies.rao.organizationCountry.label")}${
                    infos.organizationCountry.required ? "*" : ""
                  }`
                }}</span>
              </template>

              <b-autocomplete
                :value="infos.organizationCountry.value"
                ref="autocompleteOrg"
                :data="filteredOrgCountryList"
                field="code"
                :placeholder="
                  $t('policies.rao.organizationCountry.placeholder')
                "
                :disabled="infos.organizationCountry.disabled"
                @input="
                  (val) =>
                    (infos.organizationCountry.value = val
                      ? val.toUpperCase()
                      : val)
                "
                @select="
                  (option) =>
                    (infos.organizationCountry.value = option
                      ? option.code
                      : null)
                "
              >
                <!-- <template #empty>No results for {{ name }}</template> -->
              </b-autocomplete>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="column">
          <b-input-with-validation
            vid="organizationCode"
            :name="$t('policies.rao.organizationCode.label')"
            v-model="infos.organizationCode.value"
            :input-attrs="{
              placeholder: $t('policies.rao.organizationCode.placeholder'),
              disabled: infos.organizationCode.disabled,
            }"
            :fieldAttrs="{ expanded: false }"
            :rules="{ required: infos.organizationCode.required }"
          >
            <template #label>
              <span class="has-text-light">{{
                `${$t("policies.rao.organizationCode.label")}${
                  infos.organizationCode.required ? "*" : ""
                }`
              }}</span>
            </template>
          </b-input-with-validation>
        </div>

        <div
          class="column is-flex is-align-items-flex-end mb-3"
          v-if="infos.organizationTypeTIN"
        >
          <ValidationProvider
            vid="organizationCodeType"
            name="organizationCodeType"
            :rules="{ required: infos.organizationTypeTIN.required }"
            v-slot="{ errors }"
            slim
          >
            <b-field
              class="is-flex is-flex-direction-column"
              :type="{ 'is-danger': errors[0] }"
              :message="errors.length > 0 ? errors : null"
            >
              <!-- <template #label
              ><span class="has-text-light">{{
                `${$t("policies.rao.organizationTypeTIN.label")}${
                  infos.organizationTypeTIN.required ? "*" : ""
                }`
              }}</span></template
            > -->
              <b-checkbox
                v-model="infos.organizationTypeTIN.value"
                type="is-white"
                :disabled="infos.organizationTypeTIN.disabled"
              >
                <span class="has-text-light">
                  {{ $t("policies.rao.organizationTypeTIN.label") }}
                </span>
              </b-checkbox>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <b-select-with-validation
      vid="documentType"
      :name="$t('policies.rao.recognitionType.label')"
      v-model="infos.recognitionType.value"
      :placeholder="$t('policies.rao.recognitionType.placeholder')"
      :select-attrs="{
        disabled: infos.recognitionType.disabled,
      }"
      :rules="{ required: infos.recognitionType.required, is_not: 'NONE' }"
      v-show="!modifyLater"
    >
      <template #label>
        <span class="has-text-light">{{
          `${$t("policies.rao.recognitionType.label")}${
            infos.recognitionType.required ? "*" : ""
          }`
        }}</span>
      </template>
      <template #default>
        <option v-for="rec in recognitionTypeList" :key="rec" :value="rec">
          {{ $t(`policies.rao.recognitionType.${rec}`) }}
        </option>
      </template>
    </b-select-with-validation>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import BSelectWithValidation from "@/components/inputs/BSelectWithValidation.vue";
import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
import { insuranceRoleList } from "@/helpers/constants";
import { KCustomer } from "../../../helpers/constants";
import Countries from "../../../helpers/constants/countries";
import Provinces from "../../../helpers/constants/provinces";
import { formatISO, parse } from "date-fns";
import { policiesService } from "../../../services";
// import { deepCopy } from "../../../helpers";

const dateRegex = "[0-3]{1}[0-9]{1}/[0-1]{1}[0-9]{1}/[0-9]{4}";

const dateInfos = ["birthDate"];

export default {
  components: {
    ValidationProvider,
    BSelectWithValidation,
    BInputWithValidation,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    organizationEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    organizationSwitch: {
      type: Boolean,
      required: false,
      default: true,
    },
    modifyLater: {
      type: Boolean,
      required: false,
      default: false,
    },
    validateUsername: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      infos: {},
      orgDataEnabled: this.organizationEnabled,
      checkingUsername: false,
      dateRegex: dateRegex,
      formattedBirthDate: "",
      roleList: insuranceRoleList,
      countries: Countries,
      recognitionTypeList: [
        // "NONE",
        "BANK_TRANSFER",
        "WEBCAM",
        "DE_VISU",
        "EIDAS_SIGNATURE",
      ],
      masks: {
        date: {
          date: true,
          delimiter: "-",
          datePattern: ["d", "m", "Y"],
        },
      },
    };
  },
  computed: {
    filteredBirthCountries() {
      return this.filterCountries(this.infos.birthCountry.value);
    },
    filteredOrgCountryList() {
      return this.filterCountries(this.infos.organizationCountry.value);
    },
    filteredFiscalCountries() {
      return this.filterCountries(this.infos.fiscalCountry.value);
    },
    filteredProvinces() {
      let filter = this.infos.birthProvince.value;
      console.log("filter", filter);
      if (filter) {
        return Provinces.filter((option) => {
          return option.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        });
      } else {
        return Provinces;
      }
    },
  },
  watch: {
    value(newVal) {
      this.updateInfos(newVal);
    },
    organizationEnabled(newVal) {
      this.orgDataEnabled = newVal;
    },
    infos: {
      handler: function (newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    "infos.role.value": function (newVal, oldVal) {
      if (newVal === KCustomer.name) {
        this.infos.isRAO.value = false;
      } else if (oldVal === KCustomer.name) {
        this.infos.isRAO.value = null;
      }
    },
    orgDataEnabled(newVal) {
      this.$emit("orgDataToggle", newVal);
      this.$emit("update:organizationEnabled", newVal);
    },
  },
  methods: {
    filterCountries(filter) {
      if (filter) {
        return Countries.filter((option) => {
          return option.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        });
      } else {
        return Countries;
      }
    },
    updateInfos(newVal) {
      // Object.assign({}, this.infos, deepCopy(newVal));
      // this.infos = deepCopy(newVal);
      for (let key of Object.keys(newVal)) {
        this.$set(this.infos, key, { ...newVal[key] });
      }
    },
    // This is called to check the username validity on input change event
    checkUsernameExistence(username) {
      if (this.validateUsername) {
        this.checkingUsername = true;
        policiesService
          .raoUserExists(username)
          .then((res) => {
            if (res === true)
              this.$refs.domainUsername.setErrors([
                this.$t("validation.input.username_not_available"),
              ]);
          })
          .catch()
          .finally(() => (this.checkingUsername = false));
      }
    },
    getFormattedInfos() {
      const arr = Object.keys(this.infos).map((key) => {
        let val = null;
        if (this.infos[key].value !== null && this.infos[key].value !== "") {
          if (this.infos[key].type === "date" || dateInfos.includes(key)) {
            val = formatISO(
              parse(this.infos[key].value, "dd/MM/yyyy", new Date()),
              { representation: "date" }
            );
          } else {
            val = this.infos[key].value;
          }
        }
        return [key, val];
      });
      const obj = Object.fromEntries(arr);
      if (obj.organizationCode && obj.organizationCountry) {
        let prefix = "VAT";
        if (obj.organizationTypeTIN) {
          prefix = "TIN";
        }
        obj.organizationCode = `${prefix}${obj.organizationCountry}-${obj.organizationCode}`;
      }
      return obj;
    },
  },
  mounted() {
    this.updateInfos(this.value);
  },
};
</script>

<style></style>
